import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { animated, useSpring } from "react-spring"
import { Row, Col } from "reactstrap"
import { Waypoint } from "react-waypoint"

const DoubleBox = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDo2") {
          acf_home_fields {
            cardSection {
              cardHead
              cardText
              cardButtonUrl
              cardButtonText
            }
          }
        }
      }
    }
  `)

  const page = data.wpgraphql.page

  const [on, toggle] = useState(false)

  const faderin = useSpring({
    opacity: on ? 1 : 0,
    from: { opacity: 0, transform: "translate3d(0,50px,0)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)",
    },
    config: { tension: 75 },
  })

  return (
    <>
      <Waypoint
        bottomOffset="35%"
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <Row className="d-flex home__double-blocks">
        {page.acf_home_fields.cardSection.map((card, i) => (
          <Col lg={{ size: 6 }} className="d-flex home__duo" key={i}>
            <div className={`card text-white card-${i}`}>
              <animated.div style={faderin}>
                <h2 className="text-white pb-1">{card.cardHead}</h2>
                <p className="pb-1">{card.cardText}</p>
                <Link
                  className="btn btn-outline-info"
                  to={`${card.cardButtonUrl}`}
                >
                  {card.cardButtonText}
                </Link>
              </animated.div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default DoubleBox
