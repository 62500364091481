import React, { useState } from "react"
import content from "./fadetext_data"
//import './fadetext_styles.css';
import { Waypoint } from "react-waypoint"

function FadeInSection(props) {
  const [on, toggle] = useState(false)
  //const [isVisible, setVisible] = React.useState(false);

  return (
    <>
      <Waypoint
        topOffset="10%"
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <div className={`fade-in-section ${on ? "is-visible" : ""}`}>
        {props.children}
      </div>
    </>
  )
}

const FadeTextThree = () => {
  return (
    <div className="animatedText">
      {content.map(item => (
        <FadeInSection key={item.color}>
          <div className="box">
            <span>{item.word}</span>
          </div>
        </FadeInSection>
      ))}
    </div>
  )
}

export default FadeTextThree
